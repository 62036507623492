import React, { useEffect, useState } from 'react';
import Error from '../../../components/common/Error';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import CustomLabel from '../../dashboard/user-mgmt/CustomLabal';
import { onAddFlight, onUpdateFlight, onviewFlight } from '../../../api/flight-management/flight';
import { Button } from '@material-tailwind/react';
import { errorToast, successToast } from '../../../utils/toast';
import moment from 'moment';
import { getDateFormat } from '../../../helper';

const WeekdaysData = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
  { value: 'Sunday', label: 'Sunday' },
];

const flightSchema = yup.object().shape({
  flight_name: yup.string().required('This field is required').max(50, 'Max limit reached'), //required
  route: yup.string().required('This field is required'), //required
  // day: yup
  //   .string()
  //   .required("This field is required")
  //   .max(10, "Max limit reached"), //required
  day: yup.array().of(yup.string()).min(1, 'Minimum 1 required').required('Min 1 is required'),
  isRecurr: yup.string().required('This field is required'), //required
  recurrLastDate: yup.string(), //optional
  flight_takeoff_date: yup.string().required('This field is required'), //required
  takeoff_time: yup.string().required('This field is required'), //required
  landing_time: yup.string().required('This field is required'), //required
  pilot: yup.string().required('This field is required'), //required
  copilot: yup.string().required('This field is required'), //required
  // aircraftAssignment: yup
  //   .string()
  //   .required("This field is required")
  //   .max(100, "Max limit reached"), //required
  // lastMaintenanceDate: yup.string().required("This field is required"),
});

const AddFlight = ({ editable, id, setOpen, locationList, pilotList, routeList }) => {
  // get current date
  const currentDate = moment().format('YYYY-MM-DD');
  const [selectedOptions, setSelectedOptions] = useState();

  useEffect(() => {
    if (editable) {
      viewflights();
    }
    // daysFunction();
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(flightSchema),
  });

  // const daysFunction = () => {
  //   setDaysState(daysData?.map((item) => ({ label: item.name })) || []);
  // };

  const addFlight = async (values) => {
    if (values?.pilot !== values?.copilot) {
      let payload = {};
      Object.keys(values).forEach((key) => {
        payload[key] = values[key];
      });
      const response = await onAddFlight({
        ...payload,
        timezone: `${new Date().getTimezoneOffset()}`,
      });
      if (response?.status_code === 200) {
        successToast(response?.message);
        setOpen(false);
      } else {
        errorToast(response?.message || 'Something Went Wrong');
      }
    } else {
      errorToast('Pilot and Copilot cannot be same');
    }
  };

  const viewflights = async () => {
    let payload = {
      id,
    };
    const response = await onviewFlight(payload);
    if (response?.status_code === 200) {
      Object.keys(response?.data).forEach((key) => {
        if (key === 'day') {
          setValue(key, response?.data[key]);
          let weekdata = response?.data[key]?.map((item) => ({
            label: item,
            value: item,
          }));
          setSelectedOptions(weekdata);
        } else if (
          key === 'flight_takeoff_date' ||
          key === 'lastMaintenanceDate' ||
          key === 'recurrLastDate'
        ) {
          setValue(key, getDateFormat(response?.data[key], 'YYYY-MM-DD'));
        } else {
          setValue(key, response?.data[key]);
        }

        // payload[key] = values[key];
      });
      console.log('Success');
    } else {
      errorToast(response?.message || 'Something Went Wrong');
    }
  };

  const updateFlight = async (values) => {
    if (values?.pilot !== values?.copilot) {
      let payload = {
        flight_id: id,
        // timezone: `${new Date().getTimezoneOffset()}`,
      };
      Object.keys(values).forEach((key) => {
        if (
          key === 'flight_name' ||
          key === 'route' ||
          key === 'day' ||
          key === 'isRecurr' ||
          key === 'recurrLastDate' ||
          key === 'flight_takeoff_date' ||
          key === 'takeoff_time' ||
          key === 'landing_time' ||
          key === 'pilot' ||
          key === 'copilot' ||
          key === 'aircraftAssignment' ||
          key === 'lastMaintenanceDate'
        )
          payload[key] = values[key];
      });
      console.log(payload, 'payload data');
      const response = await onUpdateFlight(payload);
      if (response.status_code === 200) {
        successToast(response?.message);
        setOpen(false);
      } else {
        errorToast(response?.message || 'Something Went Wrong');
      }
    } else {
      errorToast('Pilot and Copilot cannot be same');
    }
  };

  const handleChange = (selected) => {
    setSelectedOptions(selected);
    let selectedCityData = selected.map((item) => item.label);
    setValue('day', selectedCityData);
    trigger('day');
  };
  console.log(selectedOptions, 'option');

  const onSubmit = handleSubmit((values) => {
    if (editable) {
      updateFlight(values);
    } else {
      addFlight(values);
    }
  });
  console.log(errors);

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className='w-[100%] mt-3 '>
          <CustomLabel label={'Flight Name'} />
          <input
            className='form-control'
            type='text'
            placeholder='Enter Flight Name'
            {...register('flight_name')}
          />
          <Error error={errors?.flight_name} message={errors?.flight_name?.message} />
        </div>
        <div className='w-[100%]  items-end mt-3'>
          <CustomLabel label={'Choose Route'} />

          <div className='flex flex-col items-end '>
            <Form.Select
              className='form-control'
              aria-label='Choose Route'
              // onChange={(e) => setStatus(e.target.value)}
              {...register('route')}
            >
              <option value=''>Choose From Route</option>
              {routeList?.map((item, index) => (
                <option value={item?._id}>{item?.route_name}</option>
              ))}
            </Form.Select>
          </div>
          <Error error={errors?.route} message={errors?.route?.message} />
        </div>

        {/*  */}
        <div className='w-[100%]  items-end mt-3'>
          <CustomLabel label={'Choose day'} />
          <div className='flex flex-col'>
            <Select
              options={WeekdaysData}
              isMulti
              closeMenuOnSelect={false}
              value={selectedOptions}
              onChange={handleChange}
              placeholder='Select Weekdays'
              className='basic-multi-select multiple-select-2'
              classNamePrefix='select'
              defaultValue=''
              // onKeyDown={handleKeyDown}
            />
            <Error error={errors?.day} message={errors?.day?.message} />
          </div>
        </div>

        {/*  */}

        {/* <div className="w-[100%] mt-3 ">
          <CustomLabel label={"Choose Day"} />
          <input
            className="form-control"
            type="day"
            placeholder="Enter Day"
            {...register("day")}
          />
          <Error error={errors?.day} message={errors?.day?.message} />
        </div> */}
        <div className='w-[100%]  items-end mt-3'>
          <CustomLabel label={'Choose Recurr'} />
          <div className='flex flex-col items-end '>
            <Form.Select
              className='form-control'
              aria-label='Choose isRecurr'
              // onChange={(e) => setStatus(e.target.value)}
              {...register('isRecurr')}
            >
              <option value=''>is Recurr</option>
              {/* {locationList?.map((item, index) => ( */}
              {/* <option value={item?._id}>{item?.city_name}</option> */}
              <option value={true}>true</option>
              <option value={false}>false</option>

              {/* ))} */}
              {/* <option value="Cate">Cat</option> */}
            </Form.Select>
          </div>
          <Error error={errors?.isRecurr} message={errors?.isRecurr?.message} />
        </div>
        <div className='w-[100%] mt-3 '>
          <CustomLabel label={'Choose Recurr Last Date'} />
          <input
            className='form-control'
            type='date'
            min={currentDate}
            placeholder='Enter recurr Last Date'
            {...register('recurrLastDate')}
          />
          <Error error={errors?.recurrLastDate} message={errors?.recurrLastDate?.message} />
        </div>
        <div className='w-[100%] mt-3 '>
          <CustomLabel label={'Choose Flight Takeoff Date'} />
          <input
            className='form-control'
            type='date'
            min={currentDate}
            placeholder='Enter flight takeoff date'
            {...register('flight_takeoff_date')}
          />
          <Error
            error={errors?.flight_takeoff_date}
            message={errors?.flight_takeoff_date?.message}
          />
        </div>
        <div className='w-[100%] mt-3 '>
          <CustomLabel label={'Choose Takeoff Time'} />
          <input
            className='form-control'
            type='time'
            placeholder='Enter takeoff time'
            {...register('takeoff_time')}
          />
          <Error error={errors?.takeoff_time} message={errors?.takeoff_time?.message} />
        </div>
        <div className='w-[100%] mt-3 '>
          <CustomLabel label={'Choose Landing Time'} />
          <input
            className='form-control'
            type='time'
            placeholder='Enter landing time'
            {...register('landing_time')}
          />
          <Error error={errors?.landing_time} message={errors?.landing_time?.message} />
        </div>
        <div className='w-[100%]  items-end mt-3'>
          <CustomLabel label={'Choose Pilot'} />

          <div className='flex flex-col items-end '>
            <Form.Select
              className='form-control'
              aria-label='Choose Pilot'
              // onChange={(e) => setStatus(e.target.value)}
              {...register('pilot')}
            >
              <option value=''>Choose Pilot</option>
              {pilotList?.map((item, index) => (
                <option value={item?._id}>{item?.first_name + ' ' + item?.last_name}</option>
              ))}
            </Form.Select>
          </div>
          <Error error={errors?.pilot} message={errors?.pilot?.message} />
        </div>
        <div className='w-[100%]  items-end mt-3'>
          <CustomLabel label={'Choose Copilot'} />

          <div className='flex flex-col items-end '>
            <Form.Select
              className='form-control'
              aria-label='Choose Co-Pilot'
              // onChange={(e) => setStatus(e.target.value)}
              {...register('copilot')}
            >
              <option value=''>Choose copilot</option>
              {pilotList?.map((item, index) => (
                <option value={item?._id}>{item?.first_name + ' ' + item?.last_name}</option>
              ))}
            </Form.Select>
          </div>
          <Error error={errors?.copilot} message={errors?.copilot?.message} />
        </div>
        {/* <div className="w-[100%] mt-3 ">
          <CustomLabel label={"Aircraft Assignment"} />
          <input
            className="form-control"
            type="text"
            placeholder="Enter aircraft Assignment"
            {...register("aircraftAssignment")}
          />
          <Error
            error={errors?.aircraftAssignment}
            message={errors?.aircraftAssignment?.message}
          />
        </div> */}
        {/* <div className="w-[100%] mt-3 ">
          <CustomLabel label={"Choose Last Maintenance Date"} />
          <input
            className="form-control"
            type="date"
            max={currentDate}
            placeholder="Enter lastMaintenanceDate"
            {...register("lastMaintenanceDate")}
          />
          <Error
            error={errors?.lastMaintenanceDate}
            message={errors?.lastMaintenanceDate?.message}
          />
        </div> */}
        <div className='flex mt-3 px-5'>
          <div className='px-2'>
            <Button onClick={() => setOpen(false)} size='sm' variant='outlined' className=''>
              Cancel
            </Button>
          </div>
          <div className='w-100 '>
            <Button type='submit' size='sm' variant='filled' className=''>
              Apply
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddFlight;
